@import '~sass-mq/_mq';
@import '~@angular/material/theming';
@import '../../scss/_variables';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* Couleurs Charte TOTAL 2018 */
@import 'variables';

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-light-theme);

.ff-dark-theme {
    @include angular-material-theme($app-dark-theme);
}

/* Charte TOTAL 2018 */
a, a:not([href]), a:not([href]):not([tabindex]) {
  color: map-get($app-primary, default);
  cursor: pointer;
  font-family: var(--total-font-roboto-bold);

  &:hover, &:visited {
    color: map-get($app-primary, default);
    text-decoration: none;
  }
}

/* Titles */
h1 {
  font-family: var(--total-font-nunito-regular);
  margin-bottom: 0;

  font-size: 1.5rem;
  padding-top: 25px;

  @include mq($from: desktop) {
    padding-top: 50px;
    font-size: 2.5rem;
  }
}

h2 {
  font-family: var(--total-font-roboto-regular);
  color: var(--total-red-600);

  font-size: 1.125rem;
  padding-bottom: 20px;

  @include mq($from: desktop) {
    font-size: 1.875rem;
    padding-bottom: 30px;
  }

}

h3 {
  font-size: 1rem;

  @include mq($from: desktop) {
    font-size: 1.625rem;
  }
}

h3,
h4,
h5,
h6,
.mat-dialog-title
{
  font-family: var(--total-font-roboto-medium);
  color: var(--total-gray-800);
}

button,
.mat-button-wrapper {
  font-family: var(--total-font-roboto-medium);
}

small,
div,
p,
span,
b,
i {
  font-family: var(--total-font-roboto-regular);
  font-size: 1rem;
}

input,
select,
option,
textarea,
label {
  font-family: var(--total-font-roboto-medium) !important;
}



small {
  font-size: .9rem;
}

// Material
.mat-step-header .mat-step-icon{
    &.mat-primary {
        background-color: map-get($app-primary, lighter);
        color:white;
    }
    &:hover {
        background-color: map-get($app-primary, darker);
        color:white;
    }
}

.mat-button-toggle{
    &.mat-primary{
        background-color: map-get($app-primary, lighter);
        color:white;
    }
    &:hover {
        background-color: map-get($app-primary, darker);
        color:white;
    }
    &:focus {
        background-color: map-get($app-primary, darker);
        color:white;
    }
}

.mat-raised-button, .mat-button, .mat-fab, .mat-mini-fab {

    box-shadow: none !important;
    &.mat-accent {
        &:hover {
            background-color: map-get($app-accent, lighter);
            color: map-get($app-accent, darker);
        }
    }

    &.mat-primary {
        &:hover {
            background-color: white !important;
            color: map-get($app-primary, darker) !important;
            span {
              color: map-get($app-primary, darker) !important;
              .mat-icon{
                color: map-get($app-primary, darker) !important;
              }
            }
        }
    }
    &.mat-warn {
        &:hover {
            background-color: map-get($app-warn, darker);
            color: white;
        }
    }
}

*[mat-menu-item] {
  font-family: var(--total-font-roboto-medium);
  color: var(--total-gray-800);

  &:hover {
    color: var(--total-red-600);
  }
}

.mat-tooltip {
  font-size: .75rem !important;
}

.mat-form-field {
  padding-bottom: 10px;

  @include mq($from: desktop) {
    padding-bottom: 0;
  }
}
