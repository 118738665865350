@font-face {
  font-family: 'Nunito Extra-light';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/assets/fonts/Nunito/Nunito-200.eot'); /* IE9 */
  src: url('/assets/fonts/Nunito/Nunito-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Nunito/Nunito-200.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-200.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Nunito/Nunito-200.svg#Nunito') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Nunito Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/Nunito/Nunito-300.eot'); /* IE9 */
  src: url('/assets/fonts/Nunito/Nunito-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Nunito/Nunito-300.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-300.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Nunito/Nunito-300.svg#Nunito') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Nunito Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/Nunito/Nunito-Regular.eot'); /* IE9 */
  src: url('/assets/fonts/Nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Nunito/Nunito-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-Regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Nunito/Nunito-Regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Nunito SemiBold';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/Nunito/Nunito-600.eot'); /* IE9 */
  src: url('/assets/fonts/Nunito/Nunito-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Nunito/Nunito-600.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-600.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Nunito/Nunito-600.svg#Nunito') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Nunito Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/Nunito/Nunito-700.eot'); /* IE9 */
  src: url('/assets/fonts/Nunito/Nunito-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Nunito/Nunito-700.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Nunito/Nunito-700.svg#Nunito') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Nunito ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/Nunito/Nunito-800.eot'); /* IE9 */
  src: url('/assets/fonts/Nunito/Nunito-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Nunito/Nunito-800.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-800.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Nunito/Nunito-800.svg#Nunito') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Nunito Black';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/Nunito/Nunito-900.eot'); /* IE9 */
  src: url('/assets/fonts/Nunito/Nunito-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Nunito/Nunito-900.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-900.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Nunito/Nunito-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Nunito/Nunito-900.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* roboto-100 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

* {
  &:focus {
    outline: 0 !important;
  }
}

input {

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

html,
body {
  height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
}
