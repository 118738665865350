// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$application-primary: mat-palette($mat-indigo);
$application-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$application-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$application-theme: mat-light-theme(
  $application-primary,
  $application-accent,
  $application-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($application-theme);

/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "~sass-mq/_mq";
@import "app/scss/reset";
@import "app/scss/variables";
@import "app/scss/theme/theme";

app-root {
  max-height: 100%;
}

.reduction-tooltip {
  white-space: pre-wrap !important;
}

.color-red {
  color: var(--total-red-600);
}

/**
IMPORT NG SELECT THEME OVVERIDE
**/
mat-error {
  margin-top: 5px !important;
}

.mat-form-field-infix {
  border-top: 0.33em solid transparent !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0.9em !important;
}

.ng-select {
  padding: 0.5em !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.ng-select.ng-select-focused .ng-select-container:after {
  border-color: var(--total-red-600);
  border-width: 2px;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused
  .ng-select-container.ng-appearance-outline:hover:after {
  border-color: var(--total-red-600);
  border-width: 2px;
}

.ng-select.ng-select-focused
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.001px);
  color: var(--total-red-600);
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-family: var(--total-font-roboto-medium) !important;
  color: var(--total-gray-800) !important;
  font-size: 14px !important;
}

.ng-value-label,
.ng-value {
  font-family: Arial, sans-serif !important;
}

.ng-select.ng-select-disabled
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: var(--total-red-600);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: var(--total-red-600);
  border-radius: 2px;
  color: #fff;
  padding: 2px 5px;
  margin: 0 0.4375em 0.4375em 0;
}

.width-100 {
  width: 100% !important;
}

.width-50 {
  width: 50% !important;
}

.width-25 {
  width: 25% !important;
}

.width-75 {
  width: 75% !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 8px 0 0 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 8px 8px 0 !important;
}

.mat-form-field-type-mat-input
  .mat-form-field-appearance-outline
  .mat-form-field-outline,
.mat-form-field-type-mat-input
  .mat-form-field-wrapper
  .mat-form-field-flex
  .mat-form-field-appearance-outline
  .mat-form-field-outline,
.mat-form-field-type-mat-select
  .mat-form-field-appearance-outline
  .mat-form-field-outline {
  height: 46px !important;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-20px) scale(0.75) !important;
}

.mat-form-field-label-wrapper {
  top: -0.65em;
}

.mat-paginator-page-size {
  align-items: center !important;
}

.mat-paginator
  .mat-paginator-outer-container
  .mat-paginator-page-size
  .mat-paginator-page-size-select {
  .mat-form-field-wrapper {
    padding-bottom: 4px !important;

    .mat-form-field-underline {
      display: none !important;
    }
  }
}
// .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
//   padding: inherit !important;
//   height: inherit !important;
//   padding-top: 0.4em !important;
// }

// .ng-dropdown-panel {
//   width: auto !important;
//   display: inline-table !important;
// }

hr {
  border: 1px solid #E5E5E5;
  margin: 0;
}

h1 {
  font-family: var(--total-font-nunito-regular);
  margin-bottom: 32px;
  font-size: 1.5rem;
  padding-top: unset;
  background: -webkit-linear-gradient(333deg, #029beb 0%, #00aa61 100%);
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


h2 {
  color: var(--total-gray-800) !important;
  font-size: 30px !important;
  font-family: var(--total-font-nunito-regular) !important;
}

h3 {
  color: var(--total-gray-800) !important;
  font-size: 26px !important;
  font-family: var(--total-font-roboto-medium) !important;
}

h4 {
  color: var(--total-red-600);
  font-size: 18px;
  font-weight: bold;
  font-family: var(--total-font-roboto-medium) !important;
}

h5 {
  color: var(--total-gray-800);
  font-size: 18px;
  font-family: var(--total-font-roboto-medium) !important;
}

.no-padding {
  padding: 0;
  @include mq($from: desktop) {
    padding-left: 1.5rem !important;
  }
}

.mat-card {
  margin: 0 0 20px 0;
  background: white;
  border-radius: 20px !important;
  padding: 24px !important;
  box-shadow: unset !important;
}

mat-sidenav-content.mat-drawer-content {
  overflow: hidden;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description,
.mat-select-arrow {
  color: var(--total-red-600) !important;
}

.mat-icon {
  color: var(--total-red-600);
  font-size: 22px;
}

.mat-sort-header-arrow {
  color: var(--total-red-600) !important;
}

input.mat-input-element,
.ng-input > input,
.mat-form-field-infix > textarea {
  font-family: Arial, sans-serif !important;
}

span.ag-header-cell,
span.ag-cell-value,
span.ag-header-cell-label,
mat-label {
  font-family: var(--total-font-roboto-regular) !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}
button,
a {
  border-radius: 30px !important;

  span {
    /*position: relative;*/
    /*top: 1px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto" !important;
    font-weight: 500;
  }
}

button.mat-button,
a.mat-button {
  height: 46px;
  span {
    .activated-lang {
      color: var(--total-red-600);
    }

    color: var(--total-gray-800);
    font-size: 1.15rem;
  }
}

button.mat-stroked-button {
  border-color: var(--total-red-600) !important;
  background-color: #fff !important;
  height: 46px;
  span {
    color: #000;
    font-size: 0.9rem;
  }

  .mat-ripple {
    color: var(--total-red-600);
  }
}

button.mat-raised-button,
a.mat-raised-button {
  background-color: var(--total-red-600) !important;
  border: solid 2px var(--total-red-600);
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  .mat-icon {
    color: #fff;
    /*margin-right: 0.5rem;*/
  }
  div.icon-override {
    filter: brightness(0) invert(1);
  }
  span {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 0.9rem;
  }
}

button.mat-raised-button:hover {
  background-color: white !important;
  color: var(--total-red-600);
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  .mat-icon {
    color: var(--total-red-600);
    /*margin-right: 0.5rem;*/
  }

  div.icon-override {
    filter: invert(10%) sepia(99%) saturate(5430%) hue-rotate(359deg)
      brightness(108%) contrast(113%);
  }

  span {
    display: flex;
    align-items: center;
    color: var(--total-red-600);
    font-size: 0.9rem;
  }
}

button.secondary-btn.mat-raised-button,
a.secondary-btn.mat-raised-button {
  background-color: #fff !important;
  border: solid 2px var(--total-red-600);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  .mat-icon {
    color: var(--total-red-600) !important;
    /*margin-right: 0.5rem;*/
  }

  div.icon-override {
    filter: invert(10%) sepia(99%) saturate(5430%) hue-rotate(359deg)
      brightness(108%) contrast(113%);
  }

  span {
    color: var(--total-red-600) !important;
    font-size: 0.9rem;
  }
}

button.secondary-btn.mat-focus-indicator.mat-raised-button.mat-button-base.ng-star-inserted:hover,
button.secondary-btn.mat-raised-button:hover,
a.mat-raised-button:hover {
  background-color: var(--total-red-600) !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  .mat-icon {
    color: #fff !important;
    /*margin-right: 0.5rem;*/
  }

  div.icon-override {
    filter: invert(100%) sepia(95%) saturate(16%) hue-rotate(211deg)
      brightness(105%) contrast(100%);
  }

  span {
    color: #fff !important;
    font-size: 0.9rem;
  }
}

button.tertiary-btn.mat-raised-button,
a.tertiary-btn.mat-raised-button {
  background-color: #285aff !important;
  border: solid 2px #285aff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  .mat-icon {
    color: white !important;
    /*margin-right: 0.5rem;*/
  }

  div.icon-override {
    filter: invert(100%) sepia(95%) saturate(16%) hue-rotate(211deg)
      brightness(105%) contrast(100%);
  }

  span {
    color: white !important;
    font-size: 0.9rem;
  }
}

button.tertiary-btn.mat-focus-indicator.mat-raised-button.mat-button-base.ng-star-inserted:hover,
button.tertiary-btn.mat-raised-button:hover,
a.mat-raised-button:hover {
  background-color: white !important;
  color: var(--total-blue-600);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  .mat-icon {
    color: var(--total-blue-600) !important;
    /*margin-right: 0.5rem;*/
  }

  div.icon-override {
    filter: invert(28%) sepia(81%) saturate(4445%) hue-rotate(225deg)
      brightness(101%) contrast(101%);
  }

  span {
    color: var(--total-blue-600) !important;
    font-size: 0.9rem;
  }
}

button.quarter-btn.mat-raised-button,
a.quarter-btn.mat-raised-button {
  background-color: white !important;
  color: var(--total-blue-600);
  border: solid 2px var(--total-blue-600);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  .mat-icon {
    color: var(--total-blue-600) !important;
    /*margin-right: 0.5rem;*/
  }

  div.icon-override {
    filter: invert(28%) sepia(81%) saturate(4445%) hue-rotate(225deg)
      brightness(101%) contrast(101%);
  }

  span {
    color: var(--total-blue-600) !important;
    font-size: 0.9rem;
  }
}

button.quarter-btn.mat-focus-indicator.mat-raised-button.mat-button-base.ng-star-inserted:hover,
button.quarter-btn.mat-raised-button:hover,
a.mat-raised-button:hover {
  background-color: var(--total-blue-600) !important;
  border: solid 2px var(--total-blue-600);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  .mat-icon {
    color: white !important;
    /*margin-right: 0.5rem;*/
  }

  div.icon-override {
    filter: invert(100%) sepia(95%) saturate(16%) hue-rotate(211deg)
      brightness(105%) contrast(100%);
  }

  span {
    color: white !important;
    font-size: 0.9rem;
  }
}

button.mat-raised-button[disabled][disabled],
button.mat-stroked-button:disabled {
  background-color: #b7cbd3 !important;
  border: solid 1px #b7cbd3 !important;
  span {
    color: var(--total-gray-800) !important;
  }
}

.form-select {
  border: unset !important;
  background-image: unset !important;
  line-height: unset !important;
  padding: unset !important;
}

.mat-sort-header-stem {
  display: none !important;
}
.mat-sort-header-indicator {
  color: var(--total-red-600);
}

.mat-table {
  width: 100%;
  margin-top: 30px;

  .col-width-1 {
    width: 1%;
  }

  .col-width-5 {
    width: 5%;
  }

  .col-width-10 {
    width: 10%;
  }

  .col-width-15 {
    width: 15%;
  }

  .col-width-20 {
    width: 20%;
  }

  .col-width-25 {
    width: 25%;
  }

  .col-width-30 {
    width: 30%;
  }

  .col-width-40 {
    width: 40%;
  }

  .col-width-50 {
    width: 50%;
  }

  thead {
    .mat-header-cell {
      padding-left: 12px;
      padding-right: 12px;
      color: var(--total-gray-800);
      font-size: 12px;
      font-family: var(--total-font-roboto-regular);
    }

    .mat-sort-header-button {
      // text-transform: uppercase !important;
      font-size: 10px;
      font-weight: bold;
      font-family: var(--total-font-roboto-regular);
    }
  }

  tbody {
    .mat-cell {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 14px;
      font-family: var(--total-font-roboto-regular);
      color: var(--total-gray-800);
    }

    .col-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 5vw;
    }
  }

  .mat-row:nth-child(2n){
    background-color: #f7f9fa;
  }

  .mat-header-row {
    background-color: #B7CBD3;
  }

  .mat-row:nth-child(2n + 1) {
    background-color: #fff;
  }

  .mat-row {
    border-style: unset !important;
  }

  .mat-header-row {
    border-radius: 20px 20px 0 0;
  }
}
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-top: unset !important;
  border-bottom: unset !important;
}
/* PAGINATION */
.mat-paginator-icon {
  width: 28px;
  fill: var(--total-red-400) !important;
}

.mat-icon-button[disabled] {
  opacity: 0.26;
}

.mat-paginator-range-label {
  margin: 5px 32px 0 24px !important;
}

.mat-radio-label-content {
  font-family: var(--total-font-roboto-regular) !important;
  font-size: 14px;
  margin-top: 5px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--total-red-600);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--total-red-600);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #4b96cd !important;
  color: #fff;
  font-family: var(--total-font-roboto-medium) !important;
}

.mat-chip.mat-standard-chip.unselected {
  font-family: var(--total-font-roboto-medium) !important;
  background-color: #fff;
  color: var(--total-gray-800);
  border: 2px solid var(--total-gray-800);
}

.mat-checkbox-label {
  white-space: break-spaces;
}

/** Mat hint */
.maxl {
  font-size: 0.6rem !important;
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
  color: var(--total-red-600) !important;
}

.ol-attribution.ol-uncollapsible {
  display: none !important;
}

.hstyle {
  color: var(--total-gray-800);
  font-family: var(--total-font-roboto-regular) !important;
  font-size: 40px;
  padding-top: 12px;
  line-height: 42px;

  @include mq($from: desktop) {
    padding: 1rem 0;
    font-size: 40px;
    margin: unset;
  }
}

.maticonstyle {
  cursor: pointer;
  margin-top: 18px !important;
  font-size: xx-large !important;
  margin-left: -10px !important;
  margin-right: 10px !important;
}

.chevron_right {
  color: var(--total-red-600);
}

.mat-paginator {
  border-radius: 0 0 20px 20px;
}

table.mat-table {
  border-radius: 20px;

  th.mat-header-cell:first-of-type {
    border-radius: 20px 0 0 0;
  }
  th.mat-header-cell:first-of-type::after {
    content: "";
    height: 56px;
    width: inherit;
    border-radius: 20px 0 0 0;
    display: block;
    box-shadow: 0px -25px 0px 0px white;
    position: absolute;
    top: 0;
    left: 0;
  }

  th.mat-header-cell:last-of-type {
    border-radius: 0 20px 0 0;
  }

  th.mat-header-cell:last-of-type::after {
    content: "";
    height: 56px;
    width: inherit;
    border-radius: 0 20px 0 0;
    display: block;
    box-shadow: 0px -25px 0px 0px white;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: unset !important;
}

.mat-expansion-panel {
  // background: unset !important;
  color: unset !important;
}

.mat-expansion-panel-header {
  padding: 0 7px 0 0 !important;
}

.mat-expansion-panel-body {
  padding: unset !important;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: unset !important;
}
.mat-dialog-container {
  border-radius: 20px !important;
}
.mat-expansion-indicator::after {
  border-style: unset !important;
  transform: unset !important;
  height: 20px !important;
  width: 20px !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  display: block !important;
  content: "" !important;
  background-image: url(/assets/icons/plus.svg) !important;
}
.mat-expanded .mat-expansion-indicator::after {
  border-style: unset !important;
  transform: unset !important;
  height: 20px !important;
  width: 20px !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  display: block !important;
  content: "" !important;
  background-image: url(/assets/icons/minus.svg) !important;
}
.mat-dialog-container {
  border-radius: 20px !important;
}

.info-modal {
  >.mat-dialog-container {
      padding: unset !important;
  }
}

.mat-expansion-indicator::after {
  border-style: unset !important;
  transform: unset !important;
  height: 20px !important;
  width: 20px !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  display: block !important;
  content: "" !important;
  background-image: url(/assets/icons/plus.svg) !important;
}
.mat-expanded .mat-expansion-indicator::after {
  border-style: unset !important;
  transform: unset !important;
  height: 20px !important;
  width: 20px !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  display: block !important;
  content: "" !important;
  background-image: url(/assets/icons/minus.svg) !important;
}
mat-panel-title {
  color: var(--total-red-600) !important;
  font-family: var(--total-font-roboto-medium) !important;
  font-size: 25px !important;
}

.mat-select-arrow {
  width: 20px !important;
  height: 20px !important;
  border: unset !important;
  color: var(--total-red-600);
}

.mat-select-arrow::after {
  background-repeat: no-repeat;
  display: block;
  filter: brightness(0) saturate(100%) invert(19%) sepia(92%) saturate(5824%)
    hue-rotate(357deg) brightness(86%) contrast(119%) !important;
  background-image: url(/assets/icons/arrow-down.svg) !important;
  background-size: 20px 20px !important;
  content: "";
  width: 20px !important;
  height: 20px !important;
}

.cdk-overlay-dark-backdrop {
  background: #7098a7 !important;
  opacity: 0.5 !important;
}

div.icon-override::after {
  background-repeat: no-repeat;
  display: block;
  content: "";
}

div.icon-override.white {
  filter: brightness(0) invert(1) !important;
}

div.icon-override.rotate {
  transform: rotateY(180deg)
}

div.icon-override.disabled {
  filter: invert(85%) sepia(16%) saturate(234%) hue-rotate(151deg) brightness(95%) contrast(84%) !important;
}

div.arrow-left-icon::after {
  background-image: url(assets/icons/arrow_left.svg);
}

div.arrow-right-icon::after {
  background-image: url(assets/icons/arrow-right.svg);
}

div.arrow-top-icon::after {
  background-image: url(assets/icons/arrow-top.svg);
}

div.arrow-down-icon::after {
  background-image: url(assets/icons/arrow-down.svg);
}

div.burger-menu-icon::after {
  background-image: url(assets/icons/burger_menu.svg);
}

div.check-red-icon::after {
  background-image: url(assets/icons/check_red.svg);
}

div.clock-icon::after {
  background-image: url(assets/icons/clock.svg);
}

div.close-icon::after {
  background-image: url(assets/icons/close.svg);
}

div.contact-icon::after {
  background-image: url(assets/icons/contact.svg);
}

div.cuve-icon::after {
  background-image: url(assets/icons/cuve.svg);
}

div.check-icon::after {
  background-image: url(assets/icons/check.svg);
}

div.delete-icon::after {
  background-image: url(assets/icons/delete.svg);
}

div.download-icon::after {
  background-image: url(assets/icons/download.svg);
}

div.edit-icon::after {
  background-image: url(assets/icons/edit.svg);
}

div.edit-bis-icon::after {
  background-image: url(assets/icons/edit-bis.svg);
}

div.edit-round-icon::after {
  background-image: url(assets/icons/edit-round.svg);
}

div.eye-icon::after {
  background-image: url(assets/icons/eye.svg);
}

div.info-icon::after {
  background-image: url(assets/icons/info.svg);
}

div.list-icon::after {
  background-image: url(assets/icons/list.svg);
}

div.logout-icon::after {
  background-image: url(assets/icons/logout.svg);
}

div.offline-icon::after {
  background-image: url(assets/icons/offline.svg);
}

div.online-icon::after {
  background-image: url(assets/icons/online.svg);
}

div.parrainage-icon::after {
  background-image: url(assets/icons/parrainage.svg);
}

div.print-icon::after {
  background-image: url(assets/icons/print.svg);
}

div.refresh-icon::after {
  background-image: url(assets/icons/refresh.svg);
}

div.rules-icon::after {
  background-image: url(assets/icons/rules.svg);
}

div.schemas-icon::after {
  background-image: url(assets/icons/schemas.svg);
}
  

div.search-icon::after {
  background-image: url(assets/icons/search.svg);
}

div.settings-icon::after {
  background-image: url(assets/icons/settings.svg);
}

div.sign-null-icon::after {
  background-image: url(assets/icons/line.svg);
}

div.sign1-icon::after {
  background-image: url(assets/icons/sign1.svg);
}

div.sign2-icon::after {
  background-image: url(assets/icons/sign2.svg);
}

div.sign3-icon::after {
  background-image: url(assets/icons/sign3.svg);
}

div.star-filled-icon::after {
  background-image: url(assets/icons/star_filled.svg);
}

div.star-icon::after {
  background-image: url(assets/icons/star.svg);
}

div.upload-icon::after {
  background-image: url(assets/icons/upload.svg);
}

div.user-icon::after {
  background-image: url(assets/icons/user.svg);
}

div.unfav-icon::after {
  background-image: url(assets/icons/unfav.svg);
}

div.fav-icon::after {
  background-image: url(assets/icons/fav.svg);
}

div.plus-icon::after {
  background-image: url(assets/icons/plus.svg);
}

div.minus-icon::after {
  background-image: url(assets/icons/minus.svg);
}

div.calendar-icon::after {
  background-image: url(assets/icons/calendar.svg);
}

div.save-icon::after {
  background-image: url(assets/icons/save.svg);
}

div.notif-icon::after {
  background-image: url(assets/icons/note-edit.svg);
}

div.warning-icon::after {
  background-image: url(assets/icons/warning.svg);
}

div.size-32 {
  width: 32px;
  height: 32px;
}

div.size-32::after {
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
}

div.sign-null-icon {
  width: 44px;
  height: 12px;
}

div.sign-null-icon::after {
  background-size: 44px 12px;
  width: 44px;
  height: 12px;
}

div.sign1-icon {
  width: 44px;
  height: 12px;
}

div.sign1-icon::after {
  background-size: 44px 12px;
  width: 44px;
  height: 12px;
}

div.sign2-icon {
  width: 42px;
  height: 15px;
}

div.sign2-icon::after {
  background-size: 42px 15px;
  width: 42px;
  height: 15px;
}

div.sign3-icon {
  width: 42px;
  height: 15px;
}

div.sign3-icon::after {
  background-size: 42px 15px;
  width: 42px;
  height: 15px;
}


div.size-35 {
  width: 35px;
  height: 35px;
}

div.size-35::after {
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
}

div.size-20 {
  width: 20px;
  height: 20px;
}

div.size-20::after {
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
}

div.size-22 {
  width: 22px;
  height: 22px;
}

div.size-22::after {
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
}

div.size-24 {
  width: 24px;
  height: 24px;
}

div.size-24::after {
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}

div.size-56 {
  width: 56px;
  height: 56px;
}

div.size-56::after {
  background-size: 56px 56px;
  width: 56px;
  height: 56px;
}

div.size-16 {
  width: 16px;
  height: 16px;
}

div.size-16::after {
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}
