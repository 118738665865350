$mq-breakpoints: (
    mobile:  320px,
    tablet:  768px,
    desktop: 992px,
    wide:    1200px
);
:root {
  --total-gray-400: #F7F9FA;
  --total-gray-600: #CCC;
  --total-gray-800: #374649;

  --total-red-400: #ff3C3C;
  --total-red-600: #ED0000;
  --total-red-800: #C60000;

  --total-blue-400: #007DE9;
  --total-blue-600: #285AFF;
  --total-blue-800: #004196;

  --total-brown-600: #7D7064;

  --total-orange-400: #F39C12;
  --total-orange-600: #DB7E04;

  --total-green-400: #2ECC71;
  --total-green-600: #00817A;

  --total-purple-600: #6E2D87;
  --total-pink-600: #E00090;


  // Fonts

  --total-font-nunito-extra-light:     "Nunito Extra-light",sans-serif;
  --total-font-nunito-light:     "Nunito Light",sans-serif;
  --total-font-nunito-regular: "Nunito Regular",sans-serif;
  --total-font-nunito-semi-bold:     "Nunito SemiBold",sans-serif;
  --total-font-nunito-bold:     "Nunito Bold",sans-serif;
  --total-font-nunito-extra-bold:     "Nunito ExtraBold",sans-serif;
  --total-font-nunito-black:     "Nunito Black",sans-serif;

  --total-font-roboto-thin:     "Roboto Thin",sans-serif;
  --total-font-roboto-light:    "Roboto Light",sans-serif;
  --total-font-roboto-regular:  "Roboto Regular",sans-serif;
  --total-font-roboto-medium:   "Roboto Medium",sans-serif;
  --total-font-roboto-bold:     "Roboto Bold",sans-serif;
  --total-font-roboto-black:    "Roboto Black",sans-serif;
}

@import 'helpers';
